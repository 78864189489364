<template>
<div>
  <div>
    <i :class="trigger.icon"></i> {{ trigger.label }}
  </div>

  <b-modal id="subscribe-contact-to-list-modal" v-model="isShowModal" title="Redeem Offer" size="lg">
    <div class="form-group">
      <label>Keywords</label>
      <multiselect
        v-model="automation.keywords"
        :options="keywords"
        :multiple="true"
        track-by="id"
        placeholder="Select keywords"
        label="name"
      ></multiselect>
    </div>

    <div class="form-group">
      <label>Runs</label>
      <select v-model="automation.options.runs" class="form-control">
        <option value="all">On All Redemptions</option>
        <option value="1">On 1st Redemption</option>
        <option value="2">On 2nd Redemption</option>
        <option value="3">On 3rd Redemption</option>
      </select>
    </div>

    <div class="form-group">
      <label>Runs</label>
      <select v-model="automation.options.schedule" class="form-control">
        <option value="Immediately">Immediately</option>
        <option value="Between">Between</option>
      </select>
    </div>

    <div v-if="automation.options.schedule === 'Between'" class="row mb-3">
      <div class="col-6">
        <label>Start Time</label>
        <select v-model="automation.options.start_time" class="form-control">
          <option v-for="(option, index) in timeOptions" :key="`time-${index}`" :value="index">{{ option }}</option>
        </select>
      </div>
      <div class="col-6">
        <label>End Time</label>
        <select v-model="automation.options.end_time" class="form-control">
          <option v-for="(option, index) in timeOptions" :key="`time-${index}`" :value="index">{{ option }}</option>
          <option value="24">12:00 AM</option>
        </select>
      </div>
    </div>

    <div class="form-group small-checkbox">
      <b-form-checkbox v-model="automation.options.stop_on_reply" name="stop_on_reply">
        Stop on contact reply 
        <span v-b-tooltip.hover class="cursor-pointer" title="When someone replies to a message in the automation the remainder of the automation will be cancelled" style="vertical-align: middle;">
          <i  class="uil uil-question-circle"></i>
        </span>
      </b-form-checkbox>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
export default {
  components: {
    Multiselect,
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },
    blocks: {
      type: Array,
      default: null,
    },
    trigger: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      keywords: [],
      timeOptions: [
        '12:00 AM', '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
        '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
      ]
    }
  },

  mounted() {
    this.automation.options = {
      ...{ campaigns: [], runs: 'all', times: 1, start_time: null, end_time: null, schedule: 'Immediately' },
      ...this.automation.options
    }

    this.$parent.$on('showModal', this.showModal)
  },

  methods: {
    save() {
      if (!this.automation.keywords.length)
      {
        Vue.$toast.open({
          message: 'Please select at least one keyword',
          type: 'default',
          duration: 3000,
        })
      } else {
        this.isShowModal = false
      }
    },

    showModal() {
      this.isShowModal = true
      this.getKeywords()
    },

    getKeywords() {
      // const params = {
      //   is_sample: 0,
      //   type: 'popup'
      // }

      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss">
#subscribe-contact-to-list-modal .small-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>